@import "./styles/themeVariable.scss";

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include themify($themes) {
    background: themed("colorBackground");
    color: themed("colorText");
  }
}

.mobile {
  width: auto;
  height: auto;
}
