@import "../../styles/themeVariable.scss";

.WhoIAm {
  margin: 50px;
  .cerbexa {
    color: #b02926;
    text-decoration: none;
  }

  .more {
    margin: 50px 0;
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
    .link-content {
      margin-top: 20px;
    }
    .link {
      margin-right: 20px;
    }
  }

  .icon {
    @include themify($themes) {
      background: themed("colorBackground");
      color: themed("colorText");
    }
  }
}
